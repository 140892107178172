import React, { ReactNode } from "react"
import { Box } from "@chakra-ui/layout"

type PageLayoutProps = { children: ReactNode }
export const PageLayout = ({ children }: PageLayoutProps) => (
  <Box
    minHeight="100%"
    display="flex"
    flexDirection={["column", null, "row"]}
    alignItems={["flex-end", null, "stretch"]}
  >
    <Box
      width={["100%", null, "50%"]}
      marginLeft={[0, null, "50%"]}
      marginTop={["50vh", null, "-0.2rem"]}
      flexGrow={1}
    >
      {children}
    </Box>
  </Box>
)
