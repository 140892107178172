import React, { useCallback, useRef, useState } from "react"
import { Box, SimpleGrid } from "@chakra-ui/layout"
import {
  MediaFragment,
  MediaImageFragment,
  ProductFragment,
  VideoFragment,
} from "../../shopify/sdk"
import assert from "assert-ts"
import { ShopifyImage } from "../Image"
import { Breakpoint } from "../Breakpoint"
import { Stack, useBreakpointValue } from "@chakra-ui/react"
import { useWindowSize } from "../../hooks/useWindowSize"
import { useDelayedExit } from "../../hooks/useDelayedExit"
import { getMediaImage, getMediaSize } from "../../utils/media"

const isTouch = "ontouchstart" in window

export const useCarousel = (media: MediaFragment[]) => {
  const windowSize = useWindowSize()
  const windowIsLandscape = windowSize[0] >= windowSize[1]
  const selectedMedia = media
    .filter(
      (media): media is MediaImageFragment | VideoFragment =>
        media.__typename === "MediaImage" || media.__typename === "Video"
    )
    .filter(media => {
      const size = getMediaSize(media)
      const isLandscape = size.width >= size.height
      return isLandscape === windowIsLandscape
    })
  const [index, setIndex] = useState(0)
  if (index > selectedMedia.length) {
    setIndex(0)
  }
  const [showThumbnails, setShowThumbnails] = useState(false)
  const numImages = selectedMedia.length
  const prev = useCallback(
    () => setIndex(index => (index - 1 + numImages) % numImages),
    [numImages]
  )
  const next = useCallback(
    () => setIndex(index => (index + 1) % numImages),
    [numImages]
  )
  return {
    index,
    setIndex,
    media: selectedMedia,
    prev,
    next,
    showThumbnails,
    setShowThumbnails,
  }
}
export type CarouselState = ReturnType<typeof useCarousel>

type CarouselCursorProps = { carousel: CarouselState }
export const CarouselCursor = ({ carousel }: CarouselCursorProps) => {
  const cursorRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)
  return (
    <Box
      layerStyle="fill"
      onMouseLeave={() => setVisible(false)}
      onMouseMove={e => {
        setVisible(true)
        const cursor = cursorRef.current
        if (!cursor) throw new Error("Ref not set")
        cursor.style.transform = `translate(${e.nativeEvent.offsetX}px, ${e.nativeEvent.offsetY}px)`
      }}
      cursor="none"
    >
      <Box
        position="absolute"
        pointerEvents="none"
        top={0}
        left={0}
        ref={cursorRef}
        opacity={visible ? 1 : 0}
        transition="opacity .2s"
        userSelect="none"
      >
        {carousel.index + 1} of {carousel.media.length}
      </Box>
    </Box>
  )
}

type CarouselThumbnailsProps = { carousel: CarouselState }
const CarouselThumbnails = ({ carousel }: CarouselThumbnailsProps) => {
  const numItemsPerRow = useBreakpointValue([5, 7, 9]) || 5
  const numFirstRowItems = carousel.media.length % numItemsPerRow
  const leftoverColumns = numItemsPerRow - numFirstRowItems
  const maxRatio = carousel.media.reduce((max, media) => {
    const size = getMediaSize(media)
    return Math.max(max, size.height / size.width)
  }, 0)
  const thumbnails = [
    ...carousel.media.slice(0, numFirstRowItems),
    ...Array.from({ length: leftoverColumns }).map(_ => null),
    ...carousel.media.slice(numFirstRowItems),
  ]
  const [renderThumbails, transitionIn] = useDelayedExit(
    carousel.showThumbnails,
    200
  )
  return (
    <>
      {renderThumbails && (
        <Stack
          layerStyle="fill"
          background="white"
          display={["none", "flex"]}
          flexDirection="column"
          justifyContent="flex-end"
          padding="pageMargin"
          onClick={e => e.stopPropagation()}
          opacity={transitionIn ? 1 : 0}
          transition="opacity 200ms"
        >
          <SimpleGrid columns={numItemsPerRow} spacing={2} paddingBottom={6}>
            {thumbnails.map((media, i) => (
              <Box key={i}>
                <div style={{ paddingBottom: maxRatio * 100 + "%" }} />
                {media !== null ? (
                  <ShopifyImage
                    layerStyle="fill"
                    width="100%"
                    image={getMediaImage(media)}
                    objectFit="contain"
                    objectPosition="top left"
                    onClick={() => {
                      carousel.setShowThumbnails(false)
                      carousel.setIndex(carousel.media.indexOf(media))
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }}
                    cursor="pointer"
                  />
                ) : (
                  <span />
                )}
              </Box>
            ))}
          </SimpleGrid>
        </Stack>
      )}
      <Box
        position="absolute"
        bottom={0}
        left={0}
        padding="pageMargin"
        display={["none", "block"]}
        lineHeight={1}
        textTransform="uppercase"
        onClick={e => {
          e.stopPropagation()
          carousel.setShowThumbnails(!carousel.showThumbnails)
        }}
        cursor="pointer"
        userSelect="none"
      >
        {carousel.showThumbnails ? "Back" : "View all"}
      </Box>
    </>
  )
}

type CarouselImageProps = { carousel: CarouselState }
export const CarouselImage = ({ carousel }: CarouselImageProps) => {
  const currentMedia = carousel.media[carousel.index]
  if (!currentMedia) return null
  switch (currentMedia.__typename) {
    case "MediaImage":
      return (
        <ShopifyImage
          layerStyle="fill"
          objectFit="cover"
          image={currentMedia.image!}
        />
      )
    default:
      console.warn("Unsupported media type")
      return null
  }
}

type CarouselContainerProps = React.ComponentProps<typeof Box> & {
  carousel: CarouselState
}
export const CarouselContainer = ({
  carousel,
  children,
  ...props
}: CarouselContainerProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { next, prev } = carousel
  return (
    <Box
      ref={ref}
      layerStyle="fill"
      onClick={e => {
        assert(e.currentTarget instanceof Element)
        e.clientX > e.currentTarget.clientWidth / 2 ? next() : prev()
      }}
      {...props}
    >
      {children}
      {!isTouch && <CarouselCursor carousel={carousel} />}
      {isTouch && (
        <Box
          padding="pageMargin"
          position="absolute"
          lineHeight={1}
          marginTop="-0.2em"
          top={0}
          right={0}
        >
          {carousel.index + 1} of {carousel.media.length}
        </Box>
      )}
      <Breakpoint min={1}>
        <CarouselThumbnails carousel={carousel} />
      </Breakpoint>
    </Box>
  )
}

type CarouselProps = { product: ProductFragment }
export const Carousel = ({ product }: CarouselProps) => {
  const media = product.media.edges.map(({ node: media }) => media)
  const carousel = useCarousel(media)
  return (
    <CarouselContainer carousel={carousel}>
      <CarouselImage carousel={carousel} />
    </CarouselContainer>
  )
}
