import { Box } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { Logo } from "../Logo"
import { Menu } from "../Menu"

type LayoutProps = { children: ReactNode }
export const Layout = ({ children }: LayoutProps) => (
  <>
    <Box position="fixed" layerStyle="fill" overflowY="auto">
      {children}
    </Box>
    <Menu />
    <Logo />
  </>
)
