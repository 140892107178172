import "./fonts"

export const lineHeight = 1.25
export const rem = [18, 25]
export const line = rem.map(x => x * lineHeight)
export const step = 1 / 4
export const unit = lineHeight * step

const range = (n: number, unit: (n: number) => string) => {
  const values: Record<number | string, string> = {}
  Array(n + 1)
    .fill(0)
    .forEach((_, i) => (values[i] = unit(i)))
  return values
}

const space = range(40, i => unit * i + "rem")
space.pageMargin = "0.5rem"

export const theme = {
  config: {
    useSystemColorMode: false,
  },
  styles: {
    global: (props: any) => ({
      "*": {
        position: "relative",
        wordWrap: "break-word",
        "–webkitOverflowScrolling": "touch",
      },
      html: {
        fontSize: rem.map(x => x + "px"),
        lineHeight: props.theme.lineHeights.default,
        fontFamily: props.theme.fonts.default,
        backgroundColor: props.theme.colors.background,
        scrollBehavior: "smooth",
        overscrollBehavior: "none",
        fontDisplay: "block",
        "–webkitOverflowScrolling": "touch",
        letterSpacing: "0.01em",
        overflow: "hidden",
        textDecorationThickness: "0.09em",
        textUnderlineOffset: "0.09em",
      },
      "html, body, #root": { height: "100%" },
      "input, textarea": {
        borderRadius: 0,
      },
    }),
  },
  breakpoints: {
    sm: "768px",
    md: "1024px",
    lg: "1200px",
    xl: "1600px",
  },
  lineHeights: {
    default: lineHeight,
  },
  fonts: {
    default: '"Helvetica Neue LT 57 Condensed", sans-serif',
  },
  colors: {
    background: "var(--background-color, white)",
    red: "#ff121a",
  },
  layerStyles: {
    fill: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  },
  textStyles: {
    small: {
      fontSize: ["0.75em", "0.6em"],
    },
  },
  space,
  sizes: space,
  radii: space,
  components: {
    HTML: {
      baseStyle: {
        a: {
          textDecorationLine: "underline",
          textDecorationColor: "transparent",
          transition: "text-decoration-color .2s",
          "&:hover": {
            textDecorationColor: "currentColor",
          },
        },
        "& > *:not(:last-child)": {
          marginBottom: 4,
        },
        table: {
          width: "100%",
          tableLayout: "fixed",
        },
        td: {
          verticalAlign: "top",
          display: ["block", "table-cell"],
          width: ["100% !important", "initial !important"],
          "&:not(:last-child)": {
            marginBottom: [4, 0],
          },
        },
      },
    },
    Button: {
      baseStyle: {
        border: "1px solid black",
        borderRadius: "100vw",
        paddingX: 2,
        textTransform: "uppercase",
        _hover: {},
      },
    },
  },
}
