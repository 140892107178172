import { Box } from "@chakra-ui/layout"
import { ProductContentProps } from "../.."
import { DecayImage } from "./DecayImage"
import { useRef, useState } from "react"
import { useRect } from "../../../../hooks/useRect"
import {
  useCarousel,
  useCarouselImage,
  CarouselContainer,
  CarouselState,
  CarouselImage,
} from "../../../../components/Carousel"

type DecayCarouselProps = {
  carousel: CarouselState
  onError?: (error: Error) => void
}
const DecayCarousel = ({ carousel, onError }: DecayCarouselProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const containerRect = useRect(containerRef)
  const image = useCarouselImage(carousel, containerRect)
  return (
    <Box ref={containerRef} layerStyle="fill">
      <DecayImage
        image={image}
        onError={onError}
        paused={carousel.showThumbnails}
      />
    </Box>
  )
}

export const Decay = ({ product }: ProductContentProps) => {
  const media = product.media.edges.map(({ node: media }) => media)
  const carousel = useCarousel(media)
  const [glError, setGlError] = useState<Error | null>(null)
  return (
    <CarouselContainer carousel={carousel}>
      {glError ? (
        <CarouselImage carousel={carousel} />
      ) : (
        <DecayCarousel carousel={carousel} onError={setGlError} />
      )}
    </CarouselContainer>
  )
}
