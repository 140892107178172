import createFBO from "gl-fbo"
import createShader from "gl-shader"
import vertex from "./vertex.glsl"

export const createPingpong = (
  gl: WebGLRenderingContext,
  size: [number, number],
  fragment: string
) => {
  let curr = 0
  const fbos = [
    createFBO(gl, size, { preferHalfFloat: true } as any),
    createFBO(gl, size, { preferHalfFloat: true } as any),
  ]
  if (fbos[0].color[0].format === gl.UNSIGNED_BYTE) {
    throw new Error("Floating point textures are not supported")
  }
  const shader = createShader(gl, vertex, fragment)
  return {
    bind: (uniforms: { [key: string]: any }) => {
      const prev = curr === 0 ? 1 : 0
      fbos[curr].bind()
      shader.bind()
      shader.uniforms.prev = fbos[prev].color[0].bind(1)
      Object.assign(shader.uniforms, uniforms)
    },
    unbind: () => {
      const prev = curr === 0 ? 1 : 0
      const output = fbos[curr].color[0]
      curr = prev
      gl.bindFramebuffer(gl.FRAMEBUFFER, null)
      gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight)
      return output
    },
    get shape() {
      return fbos[0].shape
    },
    set shape(shape: [number, number]) {
      fbos[0].shape = fbos[1].shape = shape
    },
    dispose: () => {
      fbos[0].dispose()
      fbos[1].dispose()
    },
    set magFilter(value: number) {
      fbos[0].color[0].magFilter = fbos[1].color[0].magFilter = value
    },
    set minFilter(value: number) {
      fbos[0].color[0].minFilter = fbos[1].color[0].minFilter = value
    },
    set wrap(value: [number, number]) {
      fbos[0].color[0].wrap = fbos[1].color[0].wrap = value
    },
  }
}
