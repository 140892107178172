import { Image } from "./Image"
import { Image as ChakraImage } from "@chakra-ui/react"
import { ImageFragment } from "../../shopify/sdk"
import { ComponentProps } from "react"

export const srcset = ({
  src400,
  src800,
  src1200,
  src1600,
  src2400,
}: ImageFragment) =>
  `${src400} 400w, ${src800} 800w, ${src1200} 1200w, ${src1600} 1600w, ${src2400} 2400w`

type ShopImageProps = ComponentProps<typeof ChakraImage> & {
  image: ImageFragment
  lazy?: boolean
}
export const ShopifyImage = ({ image, ...props }: ShopImageProps) => (
  <Image placeholder={image.placeholder} srcset={srcset(image)} {...props} />
)
