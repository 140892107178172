import React, { useEffect, useRef, useState } from "react"
import { Box, chakra } from "@chakra-ui/react"
import { useLocation } from "react-router"
import wordmark from "../../assets/wordmark.svg"
import { useWindowSize } from "../../hooks/useWindowSize"

export const Logo = () => {
  const location = useLocation()
  const windowSize = useWindowSize()
  const isLandscape = windowSize[0] > windowSize[1]
  const wMin = Math.min(windowSize[0], windowSize[1])
  const [opacity, setOpacity] = useState(0)
  const isFirstRenderRef = useRef(true)
  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false
      return
    }
    setOpacity(1)
    const timerID = setTimeout(() => setOpacity(0), 1000)
    return () => clearTimeout(timerID)
  }, [location.pathname])
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      zIndex={100}
      pointerEvents="none"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={isLandscape ? "-1.2%" : undefined}
      opacity={opacity}
      transition="opacity 0.6s"
      style={{
        width: isLandscape ? windowSize[0] + "px" : windowSize[1] + "px",
        height: isLandscape ? windowSize[1] + "px" : windowSize[0] + "px",
        transformOrigin: `${wMin / 2}px ${wMin / 2}px`,
      }}
      transform={isLandscape ? "none" : "rotate(90deg)"}
    >
      <chakra.img src={wordmark} width="80%" height="80%" />
    </Box>
  )
}
