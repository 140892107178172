import { ImageFragment, MediaFragment } from "../shopify/sdk"

export const getMediaSize = (media: MediaFragment | ImageFragment) => {
  switch (media.__typename) {
    case "Image":
      return { width: media.width!, height: media.height! }
    case "MediaImage":
      return { width: media.image!.width!, height: media.image!.height! }
    case "Video":
      const source = media.sources[0]
      return { width: source.width, height: source.height }
    default:
      throw new Error(`Unsupported media type ${media.__typename}`)
  }
}

export const getMediaImage = (media: MediaFragment | ImageFragment) => {
  switch (media.__typename) {
    case "Image":
      return media
    case "MediaImage":
      return media.image!
    case "Video":
      return media.previewImage!
    default:
      throw new Error(`Unsupported media type ${media.__typename}`)
  }
}
