import React, { useEffect, useState } from "react"
import { useShopify } from "../ShopifyProvider"
import { useQuery } from "react-query"
import { Box, BoxProps, Stack, StackProps } from "@chakra-ui/react"
import { Link } from "../Link"
import { matchPath, useLocation, useMatch } from "react-router"
import { useCart } from "../ShopifyProvider/CartProvider"
import { chakra } from "@chakra-ui/system"

const isTouch = "ontouchstart" in window

type MenuItemProps = BoxProps & {
  expanded?: boolean
  active?: boolean
  url?: string
}
const MenuItem = ({
  expanded = true,
  active = false,
  url,
  children,
  ...props
}: MenuItemProps) => {
  const [animateIn, setAnimateIn] = useState(false)
  useEffect(() => {
    setTimeout(() => setAnimateIn(true), 100)
  }, [])
  const showHighlight = useMatch("/products/:uid") !== null
  const backgroundColor = showHighlight && active ? "background" : "transparent"
  return (
    <Box
      height={expanded && animateIn ? 4 : 0}
      transition="height 0.5s"
      overflow="hidden"
      textTransform="uppercase"
      display="inline-block"
      {...props}
    >
      {url ? (
        <Link backgroundColor={backgroundColor} to={url}>
          {children}
        </Link>
      ) : (
        <chakra.span backgroundColor={backgroundColor}>{children}</chakra.span>
      )}
    </Box>
  )
}

const Indent = (props: StackProps) => (
  <Stack spacing={0} paddingLeft={[2, null, 4]} {...props} />
)

export const Menu = () => {
  const sdk = useShopify()
  const location = useLocation()
  const matchesCurrentPath = (path: string) =>
    matchPath({ path, end: false }, location.pathname) !== null

  const isProducts = matchesCurrentPath("/products")
  const [mouseOver, setMouseOver] = useState(false)
  const [toggleProducts, setToggleProducts] = useState(isProducts)
  const showProducts = isProducts || toggleProducts || mouseOver
  useEffect(() => {
    if (!matchPath({ path: "/products" }, location.pathname))
      setToggleProducts(false)
  }, [location.pathname])

  const { data } = useQuery("menu", () => sdk.menu())
  const cart = useCart()
  const cartItems = cart?.lines.edges.reduce(
    (total, { node: lineItem }) => total + lineItem.quantity,
    0
  )
  if (!data) return null
  return (
    <Stack
      position="fixed"
      top="pageMargin"
      left="pageMargin"
      marginTop="-0.2em"
      zIndex={100}
      spacing={0}
      onMouseEnter={!isTouch ? () => setMouseOver(true) : undefined}
      onMouseLeave={!isTouch ? () => setMouseOver(false) : undefined}
    >
      <MenuItem>{process.env.REACT_APP_SITE_URL!}</MenuItem>
      <Indent>
        <MenuItem
          onClick={
            isTouch ? () => setToggleProducts(!toggleProducts) : undefined
          }
        >
          /products
        </MenuItem>
        <Indent>
          {data.products.edges
            .filter(({ node: product }) => !product.tags.includes("draft"))
            .map(({ node: product }) => {
              const url = `/products/${product.handle}`
              return (
                <MenuItem
                  url={url}
                  active={matchesCurrentPath(url)}
                  expanded={showProducts}
                  key={product.handle}
                >
                  /{product.title.replaceAll(" ", "-")}
                </MenuItem>
              )
            })}
        </Indent>
        <MenuItem
          url="/information"
          active={matchesCurrentPath("/information")}
        >
          /information
        </MenuItem>
        <MenuItem url="/cart" active={matchesCurrentPath("/cart")}>
          /cart {cartItems !== undefined ? `[${cartItems}]` : ""}
        </MenuItem>
      </Indent>
    </Stack>
  )
}
