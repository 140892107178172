import { useBreakpointValue } from "@chakra-ui/react"

type BreakpointProps = { children: JSX.Element; min?: number; max?: number }
export const Breakpoint = ({
  children,
  min = -Infinity,
  max = Infinity,
}: BreakpointProps) => {
  const length = isFinite(max) ? max + 1 : isFinite(min) ? min + 1 : 1
  return (
    useBreakpointValue(
      Array.from({ length }).map((_, i) =>
        i >= min && i <= max ? children : null
      )
    ) || null
  )
}
