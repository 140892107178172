import React, { useEffect } from "react"
import { Box, ChakraProvider } from "@chakra-ui/react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import { Layout } from "../Layout"
import { theme } from "../../styles/theme"
import assert from "assert-ts"
import { ErrorBoundary } from "react-error-boundary"

import { Page } from "../../pages/Page"
import { Product } from "../../pages/Product"
import { Cart } from "../../pages/Cart"
import { ShopifyProvider, useShopify } from "../ShopifyProvider"

const shopifyUrl = process.env.REACT_APP_SHOPIFY_API_URL
const storefrontAccessToken = process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN
assert(shopifyUrl !== undefined, "REACT_APP_SHOPIFY_API_URL is not set in .env")
assert(
  storefrontAccessToken !== undefined,
  "REACT_APP_SHOPIFY_ACCESS_TOKEN is not set in .env"
)

const queryClient = new QueryClient()

const NavigateToFirstProduct = () => {
  const sdk = useShopify()
  const { data } = useQuery("menu", () => sdk.menu())
  const navigate = useNavigate()
  const firstProductHandle = data?.products.edges[0]?.node.handle
  useEffect(() => {
    if (firstProductHandle) navigate(`/products/${firstProductHandle}`)
  }, [firstProductHandle, navigate])
  return null
}

export const App = () => (
  <ErrorBoundary
    fallbackRender={({ error }) => (
      <Box position="fixed" layerStyle="fill">
        Error: {error.message}
      </Box>
    )}
  >
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ShopifyProvider
          shopifyUrl={shopifyUrl}
          storefrontAccessToken={storefrontAccessToken}
        >
          <Router>
            <Layout>
              <Routes>
                <Route path="/" element={<NavigateToFirstProduct />} />
                <Route path="products" element={<NavigateToFirstProduct />} />
                <Route path="products/:handle" element={<Product />} />
                <Route path="cart" element={<Cart />} />
                <Route path=":handle" element={<Page />} />
              </Routes>
            </Layout>
          </Router>
        </ShopifyProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </ErrorBoundary>
)
