import React, { useEffect, useRef, useState } from "react"
import { chakra } from "@chakra-ui/react"
import tick from "../../assets/Tick.svg"

type SpinnerProps = { busy: boolean }
export const Spinner = ({ busy }: SpinnerProps) => {
  const spinnerRef = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    if (!busy) return
    const el = spinnerRef.current
    if (!el) throw new Error("Ref not set")
    let frameID: number
    const tick = () => {
      const angle = Math.floor(Date.now() / 70) * 45
      el.style.transform = `rotate(${angle}deg)`
      frameID = requestAnimationFrame(tick)
    }
    tick()
    return () => cancelAnimationFrame(frameID)
  }, [busy])

  const [showTick, setShowTick] = useState(false)
  const prevBusyRef = useRef(busy)
  useEffect(() => {
    if (prevBusyRef.current === true && busy === false) {
      setShowTick(true)
      const timerID = setTimeout(() => setShowTick(false), 1000)
      prevBusyRef.current = busy
      return () => {
        setShowTick(false)
        clearTimeout(timerID)
      }
    }
    prevBusyRef.current = busy
  }, [busy])

  return (
    <>
      <chakra.span
        ref={spinnerRef}
        display={busy ? "none" : "none"}
        width="1em"
        textAlign="center"
      >
        |
      </chakra.span>
      <chakra.img
        src={tick}
        opacity={showTick ? 1 : 0}
        display="inline-block"
        width="1em"
        textAlign="center"
        transition={showTick ? undefined : "opacity 1s"}
      />
    </>
  )
}
