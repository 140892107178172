import React, { useLayoutEffect } from "react"
import { useEffect, useRef, useState } from "react"
import { createRenderer, Renderer } from "./renderer"

const useDocumentVisibility = () => {
  const [isVisible, setIsVisible] = useState(() => !document.hidden)
  useEffect(() => {
    const onVisibilityChange = () => setIsVisible(!document.hidden)
    document.addEventListener("visibilitychange", onVisibilityChange)
    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange)
  }, [])
  return isVisible
}

const useWindowFocus = () => {
  const [isFocused, setIsFocused] = useState(
    () => document.activeElement !== null
  )
  useEffect(() => {
    const onFocus = () => setIsFocused(true)
    const onBlur = () => setIsFocused(false)
    window.addEventListener("focus", onFocus)
    window.addEventListener("blur", onBlur)
    return () => {
      window.removeEventListener("focus", onFocus)
      window.removeEventListener("blur", onBlur)
    }
  }, [])
  return isFocused
}

type DecayImageProps = {
  image: HTMLImageElement | HTMLVideoElement | null
  paused?: boolean
  onReady?: () => void
  onError?: (error: Error) => void
}
export const DecayImage = ({
  image,
  paused = false,
  onReady,
  onError,
}: DecayImageProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const [renderer, setRenderer] = useState<Renderer | null>(null)
  useLayoutEffect(() => {
    const canvas = canvasRef.current
    if (canvas === null) throw new Error("Ref not set")
    try {
      const renderer = createRenderer(canvas!)
      setRenderer(renderer)
      return () => {
        setRenderer(null)
        renderer?.dispose()
      }
    } catch (e) {
      if (onError) {
        console.warn(e)
        onError(e as Error)
      } else {
        throw e
      }
    }
  }, [onError])

  useLayoutEffect(() => {
    if (!renderer) return
    if (image !== null) renderer.setImage(image)
  }, [renderer, image])

  const isFocused = useWindowFocus()
  const isVisible = useDocumentVisibility()
  useLayoutEffect(() => {
    if (!renderer || paused || !isVisible || !isFocused) return
    renderer.start()
    return () => renderer.stop()
  }, [isFocused, isVisible, paused, renderer])

  useLayoutEffect(() => {
    if (!renderer) return
    let timerID: ReturnType<typeof setTimeout>
    const onActivity = () => {
      renderer.setStrength(0)
      clearTimeout(timerID)
      timerID = setTimeout(() => renderer.setStrength(1), 10000)
    }
    onActivity()
    window.addEventListener("scroll", onActivity)
    window.addEventListener("mousemove", onActivity)
    window.addEventListener("touchstart", onActivity)
    window.addEventListener("click", onActivity)
    return () => {
      window.removeEventListener("scroll", onActivity)
      window.removeEventListener("mousemove", onActivity)
      window.removeEventListener("touchstart", onActivity)
      window.removeEventListener("click", onActivity)
      clearTimeout(timerID)
    }
  }, [renderer])

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        // background: renderer === null ? "green" : undefined,
        // background: "green",
        // transition: "opacity 0.2s",
        // safari still needing this
        transform: "translate3d(0,0,0)",
        opacity: renderer === null ? 0 : 1,
      }}
    />
  )
}
