import { useLayoutEffect, useState } from "react"
import {
  intersectionObserver,
  createIntersectionObserver,
  IObserver,
} from "../utils/observer"

const cache: Record<string, IObserver<IntersectionObserverEntry>> = {}
const getObserver = (rootMargin: string | undefined) => {
  if (rootMargin === undefined) return intersectionObserver
  if (!(rootMargin in cache)) {
    return (cache[rootMargin] = createIntersectionObserver({ rootMargin }))
  }
  return cache[rootMargin]
}

type UseVisibleOptions = {
  rootMargin?: string
  enabled?: boolean
}
export const useVisible = <T extends Element>(
  ref: React.RefObject<T>,
  { rootMargin, enabled = true }: UseVisibleOptions = {}
) => {
  const [visible, setVisible] = useState(false)
  useLayoutEffect(() => {
    if (!enabled) return
    const el = ref.current
    if (!el) throw new Error("Ref not set")
    const observer = getObserver(rootMargin)
    const onIntersect = (entry: IntersectionObserverEntry) =>
      setVisible(entry.isIntersecting)
    observer.observe(el, onIntersect)
    return () => observer.unobserve(el, onIntersect)
  }, [enabled, ref, rootMargin])
  return visible
}
