import React, { useEffect, useState } from "react"
import { Box, HStack, Stack, Text } from "@chakra-ui/layout"
import {
  useCart,
  useRemoveLineItems,
  useUpdateLineItems,
} from "../../components/ShopifyProvider/CartProvider"
import { ShopifyImage } from "../../components/Image"
import { CartLineFragment } from "../../shopify/sdk"
import { assert } from "assert-ts"
import { chakra } from "@chakra-ui/system"
import { Spinner } from "../../components/Spinner"
import { PageLayout } from "../../components/PageLayout"
import { formatPrice } from "../../utils/formatPrice"

type LineItemProps = { lineItem: CartLineFragment }

const QuantitySelect = ({ lineItem }: LineItemProps) => {
  const id = `quantity_${lineItem.id}`
  const [quantity, setQuantity] = useState(lineItem.quantity)
  const updateLineItemMutation = useUpdateLineItems()
  assert(updateLineItemMutation !== null)
  useEffect(() => {
    if (quantity !== lineItem.quantity && !updateLineItemMutation.isLoading) {
      updateLineItemMutation.mutate({
        lines: {
          id: lineItem.id,
          quantity,
          merchandiseId: lineItem.merchandise.id,
          attributes: null,
          sellingPlanId: null,
        },
      })
    }
  }, [lineItem, quantity, updateLineItemMutation])
  return (
    <HStack display="inline-flex" spacing="0.25em">
      <Box>
        [×{quantity}]
        <chakra.select
          appearance="none"
          id={id}
          layerStyle="fill"
          opacity={0}
          value={quantity}
          onChange={e => setQuantity(Number(e.target.value))}
        >
          {Array(9)
            .fill(0)
            .map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
        </chakra.select>
      </Box>
      <Spinner busy={updateLineItemMutation.isLoading} />
    </HStack>
  )
}

const LineItem = ({ lineItem }: LineItemProps) => {
  const removeLineItemMutation = useRemoveLineItems()
  assert(removeLineItemMutation !== null)
  return (
    <Box key={lineItem.id}>
      {lineItem.merchandise?.image && (
        <ShopifyImage image={lineItem.merchandise.image} width={20} />
      )}
      <Box>
        {lineItem.merchandise.title} <QuantitySelect lineItem={lineItem} />
      </Box>
      {lineItem.merchandise && (
        <HStack>
          <Text>£{lineItem.merchandise.price.amount * lineItem.quantity}</Text>
          <Text fontStyle="italic" textTransform="lowercase">
            {lineItem.merchandise.title}
          </Text>
        </HStack>
      )}
      <HStack spacing="0.25em">
        <Text
          as="button"
          display="inline-block"
          textDecorationLine="underline"
          textTransform="uppercase"
          onClick={() =>
            removeLineItemMutation.mutate({ lineIds: lineItem.id })
          }
        >
          Remove
        </Text>
        <Spinner busy={removeLineItemMutation.isLoading} />
      </HStack>
    </Box>
  )
}

export const Cart = () => {
  const cart = useCart()
  if (cart === null) return null
  const isEmpty = cart.lines.edges.length === 0
  return (
    <PageLayout>
      <Stack spacing={6} padding="pageMargin">
        {!isEmpty ? (
          cart.lines.edges.map(({ node: lineItem }) => (
            <LineItem key={lineItem.id} lineItem={lineItem} />
          ))
        ) : (
          <Text>Your cart is empty.</Text>
        )}
        {!isEmpty && (
          <Stack alignItems="flex-start" spacing={0}>
            <Text display="inline">
              Subtotal £{formatPrice(cart.cost.subtotalAmount.amount)}
            </Text>
            <Text
              as="a"
              textDecorationLine="underline"
              textTransform="uppercase"
              href={cart.checkoutUrl}
            >
              Checkout
            </Text>
          </Stack>
        )}
      </Stack>
    </PageLayout>
  )
}
