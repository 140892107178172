import React, { useRef } from "react"
import { Image as BaseImage } from "@chakra-ui/react"
import { useRect, zeroRect } from "../../hooks/useRect"
import { useVisible } from "../../hooks/useVisible"

type ImageProps = React.ComponentProps<typeof BaseImage> & {
  placeholder?: string
  srcset: string
  lazy?: boolean
}
export const Image = ({
  placeholder,
  srcset,
  lazy = true,
  ...props
}: ImageProps) => {
  const ref = useRef<HTMLImageElement>(null)
  const visible = useVisible(ref, {
    enabled: lazy,
    rootMargin: "100% 100% 100% 100%",
  })
  const observeSize = !lazy || visible
  const rect = useRect(ref, { enabled: observeSize })
  const currentSize = useRef<number | null>(null)
  if (observeSize && rect.width > 0 && rect.height > 0)
    currentSize.current = rect.width
  return (
    <BaseImage
      ref={ref}
      src={placeholder}
      srcSet={currentSize.current !== null ? srcset : undefined}
      sizes={rect !== zeroRect ? String(currentSize.current) : undefined}
      {...props}
    />
  )
}
