import ResizeObserver from "resize-observer-polyfill"
import "intersection-observer"

interface Entry {
  target: Element
}

interface ObserverConstructor<TEntry extends Entry, TOptions> {
  new (callback: (entries: TEntry[]) => void, options?: TOptions): {
    observe: (el: Element) => void
    unobserve: (el: Element) => void
  }
}

type ObserverListener<TEntry extends Entry> = (entry: TEntry) => void

export interface IObserver<TEntry extends Entry> {
  observe(el: Element, cb: ObserverListener<TEntry>): void
  unobserve(el: Element, cb: ObserverListener<TEntry>): void
}

const createObserver = <TEntry extends Entry, TOptions>(
  Observer: ObserverConstructor<TEntry, TOptions>,
  options?: TOptions
): IObserver<TEntry> => {
  const observer = new Observer(entries => entries.forEach(handle), options)
  const callbacks = new Map<Element, ObserverListener<TEntry>[]>()
  const handle = (entry: TEntry) => {
    const cbs = callbacks.get(entry.target)
    if (cbs) cbs.forEach(cb => cb(entry))
  }
  const observe = (el: Element, cb: ObserverListener<TEntry>) => {
    if (callbacks.has(el)) {
      callbacks.set(el, [
        ...(callbacks.get(el) as ObserverListener<TEntry>[]),
        cb,
      ])
    } else {
      callbacks.set(el, [cb])
      observer.observe(el)
    }
  }
  const unobserve = (el: Element, cb: ObserverListener<TEntry>) => {
    var cbs = callbacks.get(el)
    if (cbs) {
      cbs = cbs.filter(x => x !== cb)
      if (cbs.length) {
        callbacks.set(el, cbs)
      } else {
        callbacks.delete(el)
        observer.unobserve(el)
      }
    }
  }
  return { observe, unobserve }
}

export default createObserver
export const createIntersectionObserver = (
  options?: IntersectionObserverInit
) => createObserver(IntersectionObserver, options)
export const createResizeObserver = () => createObserver(ResizeObserver)

export const resizeObserver = createResizeObserver()
export const intersectionObserver = createIntersectionObserver()
