import { chakra, HStack, Text } from "@chakra-ui/react"
import { useAddLineItem } from "../../components/ShopifyProvider/CartProvider"
import { ProductVariantFragment } from "../../shopify/sdk"
import { Spinner } from "../../components/Spinner"
import { formatPrice } from "../../utils/formatPrice"

type BuyButtonProps = { variant: ProductVariantFragment; label?: string }
export const BuyButton = ({
  variant,
  label = variant.title,
}: BuyButtonProps) => {
  const addLineItemMutation = useAddLineItem()
  const addToCart =
    addLineItemMutation !== null
      ? () =>
          addLineItemMutation.mutate({
            lines: {
              merchandiseId: variant.id,
              quantity: 1,
              attributes: null,
              sellingPlanId: null,
            },
          })
      : undefined
  return (
    <HStack justify="flex-start">
      <Text width="9em">
        £{formatPrice(variant.price.amount)},{" "}
        <chakra.span fontStyle="italic" textTransform="lowercase">
          {label}
        </chakra.span>
      </Text>
      {variant.availableForSale ? (
        <Text
          as="button"
          textDecorationLine="underline"
          textTransform="uppercase"
          onClick={addToCart}
          disabled={addLineItemMutation?.isLoading}
        >
          Add to cart
        </Text>
      ) : (
        <Text textTransform="uppercase">Sold out</Text>
      )}
      <Spinner
        busy={addLineItemMutation ? addLineItemMutation.isLoading : false}
      />
    </HStack>
  )
}
