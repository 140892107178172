import React, { forwardRef } from "react"
import { Box, useStyleConfig } from "@chakra-ui/react"

type HTMLProps = React.ComponentProps<typeof Box> & { children: string }

export const HTML = forwardRef<HTMLDivElement, HTMLProps>(
  ({ children, ...props }: HTMLProps, ref) => (
    <Box
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: children.replaceAll(
          "text-decoration: underline",
          "text-decoration-line: underline"
        ),
      }}
      __css={useStyleConfig("HTML")}
      {...props}
    />
  )
)
