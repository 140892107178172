import { useEffect, useRef, useState } from "react"

type TransitionState = "exited" | "exiting" | "entered" | "entering"
export const useDelayedExit = (transitionIn: boolean, duration: number) => {
  const prevTransitionInRef = useRef(transitionIn)
  const prevTransitionIn = prevTransitionInRef.current
  useEffect(() => {
    prevTransitionInRef.current = transitionIn
  }, [transitionIn])

  const [state, setState] = useState<TransitionState>(
    transitionIn ? "entered" : "exited"
  )
  useEffect(() => {
    if (state === "entering") {
      setState("entered")
    } else if (state === "exiting") {
      const timer = setTimeout(() => setState("exited"), duration)
      return () => clearTimeout(timer)
    }
  }, [state, duration])

  if (
    transitionIn &&
    !prevTransitionIn &&
    (state === "exited" || state === "exiting")
  ) {
    setState("entering")
  } else if (
    !transitionIn &&
    prevTransitionIn &&
    (state === "entering" || state === "entered")
  ) {
    setState("exiting")
  }
  const render = state !== "exited"
  const transitionState = state === "entered"
  return [render, transitionState]
}
