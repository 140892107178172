import { createContext, ReactNode, useContext, useEffect } from "react"
import {
  CartFragment,
  CartLinesAddMutationVariables,
  CartLinesAddMutation,
  CartLinesUpdateMutationVariables,
  CartLinesUpdateMutation,
  CartLinesRemoveMutationVariables,
  CartLinesRemoveMutation,
} from "../../shopify/sdk"
import { useQuery, useMutation, useQueryClient } from "react-query"
import useLocalStorage from "react-use-localstorage"
import { useShopify } from "./ShopifyProvider"

const CartContext = createContext<CartFragment | null>(null)

export const useCart = () => useContext(CartContext)

const useCartMutation = <Variables extends { cartId: string }, Result>(
  mutationFn: (variables: Variables) => Promise<Result>
) => {
  const queryClient = useQueryClient()
  const cartId = useCart()?.id
  const mutation = useMutation(
    (variables: Omit<Variables, "cartId">) => {
      if (!cartId) throw new Error("Can't mutate cart, no cart")
      return mutationFn({ cartId, ...variables } as Variables)
    },
    { onSuccess: () => queryClient.invalidateQueries("cart") }
  )
  return cartId ? mutation : null
}

export const useAddLineItem = () => {
  const sdk = useShopify()
  return useCartMutation<CartLinesAddMutationVariables, CartLinesAddMutation>(
    variables => sdk.cartLinesAdd(variables)
  )
}

export const useUpdateLineItems = () => {
  const sdk = useShopify()
  return useCartMutation<
    CartLinesUpdateMutationVariables,
    CartLinesUpdateMutation
  >(variables => sdk.cartLinesUpdate(variables))
}

export const useRemoveLineItems = () => {
  const sdk = useShopify()
  return useCartMutation<
    CartLinesRemoveMutationVariables,
    CartLinesRemoveMutation
  >(variables => sdk.cartLinesRemove(variables))
}

type CartProviderProps = { children: ReactNode }
export const CartProvider = ({ children }: CartProviderProps) => {
  const queryClient = useQueryClient()
  const sdk = useShopify()

  const [savedCartId, setSavedCartId] = useLocalStorage("shopifyCart", "")
  const cartQuery = useQuery(
    ["cart", savedCartId],
    () => sdk.cart({ id: savedCartId }),
    { enabled: savedCartId !== "" }
  )
  const cart = cartQuery.data?.cart ?? null

  const createCartMutation = useMutation(
    () =>
      sdk.cartCreate({
        cartInput: {
          attributes: null,
          buyerIdentity: null,
          lines: null,
          discountCodes: null,
          metafields: null,
          note: null,
        },
      }),
    {
      onSuccess: data => {
        const id = data.cartCreate?.cart?.id
        if (id) setSavedCartId(id)
        queryClient.invalidateQueries("cart")
      },
    }
  )

  useEffect(() => {
    let shouldCreateNewCart =
      savedCartId === "" || (cartQuery.status === "success" && cart === null)
    if (shouldCreateNewCart && createCartMutation.status === "idle") {
      createCartMutation.mutate()
    }
  }, [cart, cartQuery.status, createCartMutation, savedCartId])

  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>
}
