import createBuffer from "gl-buffer"
import createVAO from "gl-vao"

export const createTriangle = (gl: WebGLRenderingContext) => {
  const buffer = createBuffer(gl, new Float32Array([-1, -1, -1, 4, 4, -1]))
  const vao = createVAO(gl, [{ buffer, type: gl.FLOAT, size: 2 }])
  return {
    bind() {
      vao.bind()
    },
    unbind() {
      vao.unbind()
    },
    dispose() {
      vao.dispose()
    },
    draw() {
      vao.draw(gl.TRIANGLES, 3)
    },
  }
}
