import { useEffect, useMemo, useState } from "react"
import {
  ImageFragment,
  MediaImageFragment,
  VideoFragment,
} from "../../shopify/sdk"
import { useQuery, useQueryClient } from "react-query"
import { getMediaImage } from "../../utils/media"
import { CarouselState } from "."

const loadImage = (src: string) =>
  new Promise<HTMLImageElement>(resolve => {
    const image = new Image()
    image.crossOrigin = "anonymous"
    image.onload = () => resolve(image)
    image.src = src
  })

const loadVideo = (src: string) =>
  new Promise<HTMLVideoElement | null>(resolve => {
    const video = document.createElement("video")
    video.crossOrigin = "anonymous"
    video.muted = true
    video.loop = true
    video.playsInline = true
    video.oncanplaythrough = () => {
      resolve(video)
    }
    video.src = src
    video.load()
  })

const selectSrc = (media: ImageFragment | VideoFragment, rect: DOMRect) => {
  switch (media.__typename) {
    case "Image":
      return selectImageSrc(media, rect)
    case "Video":
      return selectVideoSrc(media, rect)
  }
}

const selectMedia = (
  media: MediaImageFragment | VideoFragment,
  canPlayVideo: boolean
) => {
  switch (media.__typename) {
    case "MediaImage":
      return media.image!
    case "Video":
      return canPlayVideo ? media : media.previewImage!
  }
}

const cover = (
  src: { width: number; height: number },
  dest: { width: number; height: number }
) => {
  const scale = Math.max(dest.width / src.width, dest.height / src.height)
  return {
    width: src.width * scale,
    height: src.height * scale,
  }
}

const selectImageSrc = (image: ImageFragment, rect: DOMRect): string => {
  const imageSize = { width: image.width!, height: image.height! }
  const targetWidth = cover(imageSize, rect).width * window.devicePixelRatio
  if (targetWidth <= 400) return image.src400
  if (targetWidth <= 800) return image.src800
  if (targetWidth <= 1200) return image.src1200
  if (targetWidth <= 1600) return image.src1600
  return image.src2400
}

const selectVideoSrc = (video: VideoFragment, rect: DOMRect): string => {
  const videoSize = {
    width: video.sources[0].width!,
    height: video.sources[0].height!,
  }
  const targetWidth = cover(videoSize, rect).width * window.devicePixelRatio
  const source =
    video.sources.find(source => source.width >= targetWidth) ??
    video.sources[video.sources.length - 1]
  return source.url
}

const cacheKey = (url: string) => ["media", url]
const usePrefetchImages = (urls: string[]) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    let cancelled = false
    const preload = async () => {
      for (const url of urls) {
        await queryClient.prefetchQuery(cacheKey(url), () => loadImage(url))
        if (cancelled) return
      }
    }
    preload()
    return () => {
      cancelled = true
    }
  }, [queryClient, urls])
}

export const useCarouselImage = (carousel: CarouselState, rect: DOMRect) => {
  const [canPlayVideo, setCanPlayVideo] = useState(true)

  const placeholders = useMemo(
    () =>
      carousel.media.map(media => getMediaImage(media).placeholder as string),
    [carousel.media]
  )
  const currentMedia = useMemo(
    () => carousel.media.map(media => selectMedia(media, canPlayVideo)),
    [canPlayVideo, carousel.media]
  )
  const currentSrcs = useMemo(
    () => currentMedia.map(media => selectSrc(media, rect)),
    [currentMedia, rect]
  )
  const preloadUrls = useMemo(
    () => [
      ...placeholders,
      ...currentSrcs.filter((src, i) => currentMedia[i].__typename === "Image"),
    ],
    [currentMedia, currentSrcs, placeholders]
  )
  usePrefetchImages(preloadUrls)

  const currentPlaceholderUrl = placeholders[carousel.index]
  const currentMediaType = currentMedia[carousel.index].__typename
  const currentMediaUrl = currentSrcs[carousel.index]

  const { data: placeholderElement } = useQuery(
    cacheKey(currentPlaceholderUrl),
    () => loadImage(currentPlaceholderUrl),
    { staleTime: Infinity }
  )
  const { data: currentElement } = useQuery<
    HTMLImageElement | HTMLVideoElement | null
  >(
    cacheKey(currentMediaUrl),
    () =>
      currentMediaType === "Image"
        ? loadImage(currentMediaUrl)
        : loadVideo(currentMediaUrl),
    { staleTime: Infinity }
  )

  useEffect(() => {
    if (currentElement instanceof HTMLVideoElement) {
      currentElement.play().then(
        () => setCanPlayVideo(true),
        () => setCanPlayVideo(false)
      )

      return () => {
        currentElement.currentTime = 0
        currentElement.pause()
      }
    }
  }, [currentElement])

  return currentElement ?? placeholderElement ?? null
}
